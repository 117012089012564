import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "masthead",
  class: "site-header header-primary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Navbar = _resolveComponent("Navbar")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createVNode(_component_Navbar)
  ]))
}