
import { defineComponent } from 'vue'
import { mapGetters, mapMutations } from 'vuex'
export default defineComponent({
  computed: {
    ...mapGetters(['getAllLang', 'getCurrentLang', 'activeLang']),
  },
  methods: {
    ...mapMutations(['switchActiveLang']),
  },
  data() {
    return {
      activeNav: false,
      dropdownActive: false,
    }
  },
})
