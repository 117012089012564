(() => {
  document.addEventListener("DOMContentLoaded", () => {
    window.addEventListener('scroll', function () {
      let b = document.querySelector('.top-header').offsetHeight
      if (this.window.innerHeight >= 992)
        window.scrollY > b
          ? document.getElementById('masthead').classList.add('fixed-header')
          
          : document.getElementById('masthead').classList.remove('fixed-header')
      else {
        let c = document.querySelector('.bottom-header').offsetHeight,
          d = b + c
        window.scrollY > d
          ? document.getElementById('masthead').classList.add('fixed-header')
          : document.getElementById('masthead').classList.remove('fixed-header')
      }
    })
    if (!localStorage.getItem('loader')) {
      window.addEventListener('load', () => {
        setTimeout(fadeOutEffect, 2000)
      })
      localStorage.setItem('loader', true)
    }else{
      window.addEventListener('load', () => {
        setTimeout(fadeOutEffect, 300)
      })
      localStorage.setItem('loader', true)
    }

    function fadeOutEffect() {
      let fadeTarget = document.getElementById("siteLoader");
      let fadeEffect = setInterval(function () {
          if (!fadeTarget.style.opacity) {
              fadeTarget.style.opacity = 1;
            }
            if (fadeTarget.style.opacity > 0) {
              fadeTarget.style.opacity -= 0.1;
              fadeTarget.style.zIndex  = -1;

          } else {
              clearInterval(fadeEffect);
          }
      }, 30);

    }

  })
})()